<template>
  <div class="account-list">
    <app-list :opt="opt" @get="onGet"></app-list>
  </div>
</template>
<script>
  let validUsername = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      let reg = /^[a-zA-Z0-9]{1,16}$/;
      if (!reg.test(value)) {
        callback(new Error("子账号格式不对"));
      } else {
        callback();
      }
    }
  };
  export default {
    data() {
      let _this = this;
      return {
        opt: {
          title: "房管员列表",
          search: [{
            key: "username",
            label: "账号",
            rules: [{
              validator: validUsername,
              trigger: ['blur']
            }]
          },
          {
            label: "姓名",
            key: "realname"
          },
          {
            label: "角色",
            key: "housekeeperRoleId",
            type: "select",
            opt: {
              list: []
            },
          },
          {
            label: "账号状态",
            key: "userstate",
            type: "select",
            opt: {
              list: [{
                  label: "启用",
                  value: "2"
                },
                {
                  label: "禁用",
                  value: "3"
                }
              ]
            }
          }],
          columns: [{
              label: "账号",
              key: "username",
              on: row => {
                _this.$router.push("/main/account/detail/show/" + row.userId);
              }
            },
            // { label: "密码", key: "pass" },
            {
              label: "姓名",
              key: "realname"
            },
            {
              label: "联系电话",
              key: "phonenum"
            },
            {
              label: "角色",
              key: "roleStr"
            },
            {
              label: "添加时间",
              key: "createdDate"
            },
            {
              label: "账号状态",
              key: "state",
              type: "switch",
              opt: {
                on(form) {
                  return _this
                    .post(
                      "user-service/user/modifyUserAccountState", {
                        userId: form.userId,
                        userState: !form.state ? "2" : "3"
                      }, {
                        isUseResponse: true,
                        isNotShowError: true
                      }
                    )
                    .then(res => {
                      if (res.data.code == 0) {
                        return {
                          state: true
                        };
                      }
                    })
                    .catch(res => {
                      return {
                        state: false,
                        msg: res.data.msg
                      };
                    });
                }
              }
            }
          ],
          exportButtons: [{
            type: "Export",
            url: this.EXport + "/user-service/subAccount/v1/export",
            listName: "房管员列表"
          }],
          buttons: [{
              type: 0,
              on() {
                _this.$router.push("/main/account/detail/add/0");
              }
            },
            {
              type: 1,
              on(data) {
                _this.$router.push(
                  "/main/account/detail/edit/" + data.data.userId
                );
              }
            },
            {
              type: 2,
              on(data) {
                if (data.list.length > 1) {
                  return _this.$message({
                    showClose: true,
                    message: "不支持多个删除,只支持单个删除!",
                    type: "warning"
                  });
                }
                const h = _this.$createElement;
                _this
                  .$msgbox({
                    title: "删除提示",
                    message: h("p", null, [
                      h(
                        "span", {
                          style: "color: #333;font-size: 16px;margin-left:98px"
                        },
                        "确定要删除房管员吗？"
                      ),
                      h(
                        "p", {
                          style: "font-size:14px;margin-left:98px"
                        },
                        "删除房管员后，房管员账号将不可用"
                      )
                    ]),
                    showCancelButton: true,
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                  })
                  .then(action => {
                    var dto = {
                      userId: data.list[0].userId,
                    };
                    _this
                      .post("user-service/subAccount/delete", dto)
                      .then(function () {
                        _this.$message({
                          showClose: true,
                          message: "删除房管员成功！",
                          type: "success"
                        });
                        data.refresh();
                      });
                  });
              }
            }
          ]
        }
      };
    },
    created() {
      console.log("user-list created!!");
      this.getRoleList();
    },
    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          ...opt.searchForm
        };
        this.post("user-service/subAccount/list", dto, {
          isUseResponse: true
        }).then(res => {
          if (res.data.code == 0) {
            let data = res.data.data;
            data.forEach(item => {
              if(item.roleNames) {
                item.roleStr = item.roleNames.join(",");
              }else {
                item.roleStr = '';
              }
              item.state = item.userstate == 2 ? true : false;
              // item.phonenum =item.phonenum
              //   ? item.phonenum.substring(0, 3) +
              //     "****" +
              //     item.phonenum.substring(7, item.phonenum.length)
              //   : "";
            });
          }
          opt.cb(res.data);
        });
      },
      // 获取角色列表
      getRoleList() {
        this.post("user-service/user/v1/housekeeper-role/list", {
          roleType: '3'
        }, {
          contentType: "application/x-www-form-urlencoded",
          isUseResponse: true
        }).then(res => {
            if(res.data.code == 0) {
              let list = res.data.data;
              this.opt.search.forEach(item => {
              if(item.key == 'housekeeperRoleId') {
                list = JSON.parse(JSON.stringify(list).replace(/roleName/g, "label"));
                list = JSON.parse(JSON.stringify(list).replace(/id/g, "value"));
                item.opt.list = list;
              }
            })
          }
        });
      }
    }
  };
</script>